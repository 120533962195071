$teal: #18c3bb;
$teal-border: #089d96;
$teal-link-border: #2ec8c0;
$teal-background: #7accc8;
$teal-background-light: #c4f0ee;
$teal-dark: #01645f;
$orange: #ff7801;
$pink: #d2096a;
$gray-blue: #6d97b9;
$light-green: #98cf61;
$black: #1e2022; // rgb(30, 32, 34)
$yellow-text: #ffc107;
$white: #ffffff;

$gray: #98a0b0;
$gray-text: #5c667a;
$gray-light: #777;
$gray-lighter: #b8b8b8;
$gray-lightest: #c3ccd2;
$gray-extra-light: #f3f7f9;
$gray-extra-light-home: #eaf0f5;
$gray-border: #d7dee2;
$gray-border-light: #dce0e0;
$gray-backgrounds: #d6dde1;
$gray-dark: #4f596c;
$gray-darker: #292f3b;
$gray-status: #8aaecb;
$gray-shadow: #cdcdcd;

$purple: #933cdd;
$purple-light: #d6baee;
$purple-lighter: #f6eefc;
$purple-dark: #5c667a;
$purple-status: #b791d8;

$green: #2fa205;
$green-light: #b3e8a0;
$green-extra-light: #ddf2d5;
$green-dark: #1d6b01;

$blue-light: #edfbfa;
$blue-text-secondary: #b9cbeb;
$blue-dark: #252e54;
$blue-darker: #050f39;
$blue-darkest: #001234;

$yellow-light: #fcf6ed;

$fuxia-background: #f9ebf2;
$fuxia-border: #f9cae0;
$fuxia: #e5167a;

$semi-transparent-dark-bg: rgba(#333, 0.7);
$orange-light: #ff9c44;
$orange-lighter: #ffd7b4;
$orange-dark: #9d4e09;
$silver-light: #c5c3c3;
$yellow: #ffd925;
$green-lighter: #8dc63f;
$silver-lighter: #bbbbc1;

$sorbus: #e564a2;
$red: #ff2e37;
$red-darker: #de2038;

@import "../../../styles/mixins";

$grid-breakpoint-xs: 0;
$grid-breakpoint-sm: 600px;
$grid-breakpoint-md: 1024px;
$grid-breakpoint-lg: 1624px;
$gutter-width-mobile: 8px;
$gutter-width: 24px;

$grid-margin: 20px;
$neg-grid-margin: -20px;

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: $neg-grid-margin;
}

.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

// Manually create specific gap classes
.gap1 {
  @include gap(1);
}

.gap2 {
  @include gap(2);
}

.gap3 {
  @include gap(3);
}

.gap4 {
  @include gap(4);
}

.gap5 {
  @include gap(5);
}

.column {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: $grid-margin;
}

@media screen and (min-width: $grid-breakpoint-xs) {
  .column {
    flex-basis: 100%;
  }
}

@media screen and (min-width: $grid-breakpoint-sm) {
  .column {
    flex-basis: 50%;
  }
}

@media screen and (min-width: $grid-breakpoint-md) {
  .column {
    flex-basis: 33.3%;
  }
}

@media screen and (min-width: $grid-breakpoint-lg) {
  .column {
    flex-basis: 25%;
  }
}

$font-size-base: 15px;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 600;
$font-family-sans-serif: "Poppins";
$font-family-base: $font-family-sans-serif, sans-serif;
$text-color: #222;
$text-additional: #5c667a;
$text-disabled: #777;

// Transition defaults
$transition-duration: 0.05s;
$transition-timing-function: ease-in-out;

@mixin outline($color: rgba(#29d, 0.3)) {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 2px $color;
}

@mixin triangle($direction, $width, $height, $color) {
  @if ($direction == "up") {
    transform: rotate(135deg);
  } @else if ($direction == "down") {
    transform: rotate(-45deg);
  } @else if ($direction == "left") {
    transform: rotate(45deg);
  } @else if ($direction == "right") {
    transform: rotate(-135deg);
  }

  transform-origin: 0 0;
  border-width: $height calc($width / 2) 0;
  border-style: solid;
  border-color: transparent transparent $color $color;
  box-shadow: 0 2px 15px 0 rgba(30, 32, 34, 0.15);
  background-color: $color;
  width: 0;
  height: 0;
  content: "";
}

@mixin arrow($size, $color) {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -($size);
  margin-left: -($size);
  border-width: $size;
  border-bottom-width: 0;
  border-style: solid;
  border-color: transparent;
  border-top-color: $color;
  width: 0;
  height: 0;
}

@mixin arrow-after($size, $color) {
  display: block;
  position: absolute;
  bottom: 2px;
  margin-left: -(calc($size - 1px));
  border-width: calc($size - 1px);
  border-bottom-width: 0;
  border-style: solid;
  border-color: transparent;
  border-top-color: $color;
  width: 0;
  height: 0;
  content: " ";
}

@mixin arrow-with-shadow($arrow-size, $border-color, $arrow-color) {
  @include arrow($arrow-size, $border-color);

  &::after {
    @include arrow-after($arrow-size, $arrow-color);
  }
}

@mixin multi-line-ellipsis($line-height, $line-count, $bg-color) {
  overflow: hidden;
  position: relative;
  line-height: $line-height;
  max-height: calc($line-height * $line-count);
  margin-right: -1em;
  padding-right: 0.5em;
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bg-color;
  }
}

@mixin hideScrollBars {
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox

  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@function gap-size($number) {
  @return calc($number / 4) * 1rem;
}

@mixin gap($number) {
  gap: gap-size($number);
}

$screen-xs-slider: 320px;
$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-xl: 1440px;

@mixin large-and-extra-large {
  @media (min-width: calc($screen-md + 1px)) {
    @content;
  }
}

@mixin extra-large {
  @media (min-width: calc($screen-lg + 1px)) {
    @content;
  }
}

@mixin large {
  @media (min-width: calc($screen-md + 1px)) and (max-width: $screen-lg) {
    @content;
  }
}

@mixin medium {
  @media (min-width: calc($screen-sm + 1px)) and (max-width: $screen-md) {
    @content;
  }
}

@mixin small {
  @media (min-width: calc($screen-xs + 1px)) and (max-width: $screen-sm) {
    @content;
  }
}

@mixin xs {
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin xs-slider {
  @media (max-width: $screen-xs-slider) {
    @content;
  }
}

@mixin medium-and-large {
  @media (min-width: calc($screen-sm + 1px)) {
    @content;
  }
}

@mixin small-and-medium {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin xs-and-small {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin slv-large {
  @media (min-width: calc($screen-xl + 1px)) {
    @content;
  }
}

@mixin slv-medium {
  @media (min-width: calc($screen-lg + 1px)) and (max-width: $screen-xl) {
    @content;
  }
}

@mixin slv-small {
  @media (max-width: $screen-lg) {
    @content;
  }
}

@mixin slv-small-and-medium {
  @media (max-width: $screen-xl) {
    @content;
  }
}

@mixin smallDesktops {
  @media (max-width: calc($screen-xl - 1px)) {
    @content;
  }
}

@mixin ipads {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin phones {
  @media (max-width: calc($screen-sm - 1px)) {
    @content;
  }
}

// Global
$z-index-file-uploader-overlay: calc(2147483647 + 1); // chat icon + 1
$z-index-dropdown-body: 101;
$z-index-navbar: 1000;
$z-index-filters: 999;
$z-index-tooltip: 998;
$z-index-date-picker: 997;
$z-index-video-player-overlay: 1001;
$z-index-popover: 1;
$z-index-popver-triangle: 1;
$z-index-slick-arrow: 1;

// Dashboard
$z-index-dashboard-product-editor-action-bar: 100;
$z-index-dashboard-product-index-action-bar: 100;
$z-index-dashboard-product-index-notification-bar: 200;

// Marketplace
$z-index-mobile-filters: calc($z-index-navbar + 1);
$z-index-mobile-filters-action-bar: calc($z-index-mobile-filters + 1);

// Locations
$z-index-locations-map: calc($z-index-navbar + 1);
$z-index-locations-toggle-map: calc($z-index-locations-map + 1);
$z-index-locations-mobile-map: calc($z-index-navbar - 1);

// Modal navigation header
$z-index-modal-navigation-header: 1;
$z-index-invitation-modal: 1010;
$z-index-invitation-modal-overlay: 1000;

@import "app/styles/index";
@import "app/styles/colors";
@import "app/styles/layout";
@import "app/styles/fonts";
@import "app/styles/mixins";
@import "app/styles/z-indexes";
@import "app/styles/screens";

$triangle-size: 15px;
$mobile-filters-action-bar-height: 60px;

h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.layout {
  width: 100%;

  @include small {
    margin-top: 125px;
  }

  @include xs {
    margin-top: 0;
  }

  .searchField {
    font-size: 14px;
    width: 260px;
    padding-left: 40px;
  }

  .searchIcon {
    border-right: none;
  }

  .dropdownTrigger {
    font-size: 14px;
    width: 170px;
  }

  .dropdownBody {
    display: flex;
    flex-flow: column nowrap;
  }

  .dropdownBodyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;

    .label {
      width: 155px;
      font-size: 14px;
      font-weight: $font-weight-base;
    }
  }
}

.headerContainer {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid $gray-lightest;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  max-width: 1180px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: calc($screen-md + 1px)) and (max-width: 1200px) {
    max-width: 904px;
  }

  @include medium {
    max-width: 904px;
  }

  @include phones {
    flex-flow: column nowrap;
    align-items: center;
    padding: 10px;
  }
}

.title {
  font-size: 22px;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  color: $text-color;
  font-family: $font-family-base;
  font-weight: 600;

  @include xs {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.filters {
  display: flex;
  width: 440px;
  justify-content: space-between;

  @include xs {
    display: none;
  }
}

.manageFilters {
  display: flex;
  justify-content: space-between;

  @include xs {
    display: none;
  }
}

.manageAdminFilters {
  display: flex;
  width: 800px;
  justify-content: space-between;

  @include xs {
    display: none;
  }
}

.listContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridHeader {
  max-width: 1180px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  @media (min-width: calc($screen-md + 1px)) and (max-width: 1200px) {
    max-width: 904px;
  }

  @include medium {
    max-width: 904px;
  }

  @include small {
    width: 90%;
  }

  @include xs {
    max-width: 315px;
  }
}

.providersCount {
  color: $gray-text;
}

.headerButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadIcon {
  margin-right: 10px;
}

.popoverTrigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
}

.popoverTrigger > button {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
  font-size: 14px;
}

.popoverBody {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $gray-text;
  width: 200px;
  height: 80px;
  background: $white;
  box-shadow: 0 2px 15px 0 rgba(30, 32, 34, 0.15);
  margin-top: -50px;
  z-index: $z-index-popover;

  &::before {
    @include triangle("up", $triangle-size, $triangle-size, $white);

    position: absolute;
    top: 0;
    right: 20px;
    z-index: $z-index-popver-triangle;
  }
}

.popoverRow {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background-color: $white;
  z-index: $z-index-popver-triangle + 1;

  &:hover {
    background-color: $purple-lighter;
    cursor: pointer;
  }

  &:last-child .sortSelection {
    border-bottom: none;
  }

  .sortSelection {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border-bottom: 1px solid $gray-border-light;
    display: flex;
    align-items: center;
  }
}

.gridBody {
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: space-between;

  @media (min-width: calc($screen-md + 1px)) and (max-width: 1200px) {
    max-width: 904px;
  }

  @include medium {
    max-width: 904px;
  }

  @include small {
    width: 90%;
  }

  @include xs {
    max-width: 375px;
    justify-content: center;
  }

  // phantomProvider is to solve a flexbox problem
  .providerTile,
  .phantomProvider {
    @include medium {
      width: 288px;
    }

    @include small {
      width: 220px;
    }

    @include xs {
      width: 315px;
    }
  }
}

.phantomProvider {
  position: relative;
  width: 280px;
  height: 0;
}

.paginationBar {
  max-width: 1180px;
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-lightest;

  @media (min-width: calc($screen-md + 1px)) and (max-width: 1200px) {
    max-width: 904px;
  }

  @include medium {
    max-width: 904px;
  }

  @include small {
    width: 90%;
  }

  @include xs {
    max-width: 375px;
    height: 175px;
    flex-flow: column nowrap;
    justify-content: center;
  }
}

.paginationInfo {
  font-size: 14px;
  color: $gray-text;

  @include xs {
    margin-top: 15px;
  }
}

.seoContainer {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seoTextContainer {
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (min-width: calc($screen-md + 1px)) and (max-width: 1200px) {
    max-width: 904px;
  }

  @include medium {
    max-width: 904px;
    flex-flow: column nowrap;
    align-items: center;
  }

  @include small {
    flex-flow: column nowrap;
    align-items: center;
    width: 90%;
  }

  @include xs {
    flex-flow: column nowrap;
    max-width: 315px;
    align-items: center;
  }

  .seoText {
    margin-top: 2rem;
    max-width: 820px;
    display: flex;
    flex-flow: column nowrap;
    flex: 2 0 0;

    @include medium {
      max-width: 904px;
      width: 100%;
      flex: 1 0 auto;
      order: 2;
    }

    @include small {
      width: 100%;
      flex: 1 0 auto;
      order: 2;
    }

    @include xs {
      max-width: 315px;
      flex: 1 0 auto;
      order: 2;
    }

    h1 {
      font-size: 18px;
      color: $gray-text;
      margin-bottom: 15px;
    }

    h2 {
      font-size: 16px;
      color: $gray-text;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 14px;
      color: $gray-text;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      color: $gray-text;
      margin-bottom: 10px;
      margin-top: 0;
      line-height: 1.7;

      @include medium {
        max-width: 596px;
      }

      @include xs {
        font-size: 13px;
      }
    }

    section {
      margin-bottom: 25px;
    }
  }
}

/* Overrides for mobile with screen bigger than $xs */
.mobileLayout {
  .headerContainer {
    position: sticky;
    top: 72px;
    z-index: $z-index-filters;
    background-color: $white;
  }

  .listContainer {
    padding-top: 50px;
  }

  .mobileFiltersActionBar {
    display: flex;
    position: fixed;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    bottom: 0;
    width: 100%;
    height: $mobile-filters-action-bar-height;
    z-index: $z-index-mobile-filters-action-bar;
    background-color: $white;
  }

  .mobileFilters {
    display: flex;
    position: fixed;
    flex-flow: column nowrap;
    top: 110px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: $z-index-filters;
    padding: 32px 30px #{calc($mobile-filters-action-bar-height + 20px)};
    background-color: $white;

    .searchField {
      width: 100%;
    }

    .dropdownTrigger {
      margin-top: 20px;
    }

    .dropdownBody {
      width: 100%;

      .label {
        width: 90%;
      }
    }

    .dropdownBodyRow {
      width: 100%;
    }
  }

  .mobileFiltersActionBarButton {
    margin: 0 10px;
  }

  @include small {
    margin-top: 0;

    .filters {
      display: none;
    }

    .gridBody {
      justify-content: center;
    }

    .providerTile {
      width: 100%;
    }

    .paginationBar {
      width: 90%;
      height: 175px;
      flex-flow: column nowrap;
      justify-content: center;
    }

    .paginationInfo {
      margin-top: 15px;
    }
  }
}

.viewAllButton {
  color: $purple-dark;
  border-bottom: 1px dotted $gray-text;
  margin-left: 10px;
  cursor: pointer;
}

.addNewButton {
  margin-top: 10px;
}
